const en = {
    home: 'Home',
    contact: 'Contact',
    no_notifications: 'No system notifications',
    added_at: 'Added',
    contact_info: 'Contact info',
    email: 'Email',
    phone: 'Phone',
    status: 'Status',
    status_1: 'We are aware of the issue',
    status_2: 'We are investigating the issue',
    status_3: 'We are fixing the issue',
    status_4: 'Issue has been fixed',
    status_5: 'Information',
    global_status: 'Global Systems Status',
    global_status_1: 'All systems are OK',
    global_status_2: 'Some systems are non-operational',
    global_status_3: 'All systems are down',
};
export default en;
