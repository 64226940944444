import { Box } from '@mui/material';
import { Fragment } from 'react';
import { LanguageSelector } from '../../languagepack/Languagepack';
import MainMenu from '../menus/MainMenu';

const DefaultLayout = ({ children }) => {
    return (
        <Fragment>
            <LanguageSelector />

            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <MainMenu />
                </Box>

                {children}
            </Box>
        </Fragment>
    );
};
export default DefaultLayout;
