import { Box, Typography } from '@mui/material';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import useLanguagepack from '../../languagepack/Languagepack';

const GlobalStatus = ({ status }) => {
    const languagepack = useLanguagepack();

    const renderStatusIcon = () => {
        switch (status) {
            case 1: {
                return (
                    <CloudDoneOutlinedIcon
                        sx={{ fontSize: 128 }}
                        color="success"
                    />
                );
            }
            case 2: {
                return (
                    <CloudOffOutlinedIcon
                        sx={{ fontSize: 128 }}
                        color="warning"
                    />
                );
            }
            case 3: {
                return (
                    <CloudOffOutlinedIcon
                        sx={{ fontSize: 128 }}
                        color="error"
                    />
                );
            }
            default: {
                return (
                    <CloudOffOutlinedIcon
                        sx={{ fontSize: 128 }}
                        color="error"
                    />
                );
            }
        }
    };

    return (
        <Box
            sx={{
                padding: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {renderStatusIcon()}
                <Typography
                    textAlign="center"
                    variant="h5"
                    sx={{ userSelect: 'none' }}
                >
                    {languagepack[`global_status_${status}`]}
                </Typography>
            </Box>
        </Box>
    );
};
export default GlobalStatus;
