/* eslint-disable react-hooks/exhaustive-deps*/
import { Box, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import apiClient from '../apiClient/apiClient';
import DefaultLayout from '../components/layouts/Default';
import Loading from '../components/loading/Loading';
import NotificationDisplay from '../components/notifications/NotificationsDisplay';
import GlobalStatus from '../components/status/GlobalStatus';

const IndexPage = () => {
    const [messagesData, setMessagesData] = useState();
    const [globalStatus, setGlobalStatus] = useState();
    const [page, setPage] = useState(1);

    const handleGetNotifications = async () => {
        const response = await apiClient.getNotifications(page).catch(() => {});
        setMessagesData(response);
    };

    const handleGetGlobalStatus = async () => {
        const response = await apiClient.getGlobalStatus().catch(() => {});
        setGlobalStatus(response?.data);
    };

    useEffect(() => {
        handleGetNotifications();
        handleGetGlobalStatus();
        const timer = setInterval(() => {
            handleGetNotifications();
            handleGetGlobalStatus();
        }, 3 * 60 * 1000);

        return () => {
            clearInterval(timer);
        };
    }, [page]);

    return (
        <DefaultLayout>
            {!globalStatus && <Loading />}
            {!!globalStatus && (
                <Box sx={{ padding: 2 }}>
                    {' '}
                    <GlobalStatus status={globalStatus.status} />
                </Box>
            )}
            {!messagesData && <Loading />}
            {!!messagesData && (
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <NotificationDisplay
                        notifications={messagesData.data || []}
                    />
                    <Pagination
                        showFirstButton
                        showLastButton
                        count={messagesData.meta.pages}
                        page={page}
                        onChange={(_, page) => {
                            setPage(page);
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 2,
                        }}
                    />
                </Box>
            )}
        </DefaultLayout>
    );
};
export default IndexPage;
