import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const NavigateProvider = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigateService._navigate = navigate;
    }, [navigate]);

    return <Fragment>{children}</Fragment>;
};

class NavigateService {
    _navigate = null;

    navigate(url) {
        this._navigate(url);
    }
}
const navigateService = new NavigateService();
export default navigateService;
