import { Box, Button, Dialog, Portal, Typography } from '@mui/material';
import { createContext, useContext, useState } from 'react';

const ConfirmationContext = createContext(
    ({
        content = 'Are you sure you want to do this action?',
        onConfirm,
        onAbort,
    }) => {}
);

export const ConfirmationProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const [onConfirm, setOnConfirm] = useState();
    const [onAbort, setOnAbort] = useState();

    const renderDialog = ({
        content = 'Are you sure you want to do this action?',
        onConfirm,
        onAbort,
    }) => {
        setContent(content);
        setOnConfirm(() => onConfirm);
        setOnAbort(() => onAbort);
        setOpen(true);
    };

    const handleClose = () => {
        if (typeof onAbort === 'function') {
            onAbort();
        }
        setOpen(false);
    };

    const handleConfirm = () => {
        if (typeof onConfirm === 'function') {
            onConfirm();
        }
        setOpen(false);
    };

    return (
        <ConfirmationContext.Provider value={renderDialog}>
            <Portal>
                <Dialog open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h6" textAlign="center">
                            {content}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                marginTop: 2,
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleConfirm}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Portal>
            {children}
        </ConfirmationContext.Provider>
    );
};

const useConfirmation = () => {
    const confirmation = useContext(ConfirmationContext);
    return confirmation;
};
export default useConfirmation;
