import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useLanguagepack from '../../languagepack/Languagepack';

const MainMenu = () => {
    const languagepack = useLanguagepack();
    const navigate = useNavigate();
    const location = useLocation();
    const value = location.pathname;

    const tabOptions = [
        {
            href: '/',
            label: languagepack.home,
        },
        {
            href: '/contact',
            label: languagepack.contact,
        },
    ];

    return (
        <Tabs
            value={value}
            onChange={(_, newValue) => {
                navigate(newValue);
            }}
        >
            {tabOptions.map((tab) => (
                <Tab key={tab.href} label={tab.label} value={tab.href} />
            ))}
        </Tabs>
    );
};
export default MainMenu;
