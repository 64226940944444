import { Box, MenuItem, Select } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import en from './langs/en';
import pl from './langs/pl';

const LanguageContext = createContext('pl');
const LanguagepackContext = createContext({});

const languageMap = {
    pl: pl,
    en: en,
};

export const LanguagepackProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('lng') || 'pl'
    );
    const [languagepack, setLanguagepack] = useState(
        languageMap[selectedLanguage]
    );

    useEffect(() => {
        setLanguagepack(languageMap[selectedLanguage]);
    }, [selectedLanguage]);

    return (
        <LanguageContext.Provider
            value={{
                language: selectedLanguage,
                setLanguage: (language) => {
                    localStorage.setItem('lng', language);
                    setSelectedLanguage(language);
                },
            }}
        >
            <LanguagepackContext.Provider value={languagepack}>
                {children}
            </LanguagepackContext.Provider>
        </LanguageContext.Provider>
    );
};

const useLanguagepack = () => {
    const languagepack = useContext(LanguagepackContext);
    return languagepack;
};
export default useLanguagepack;

export const useLanguage = () => {
    const language = useContext(LanguageContext);
    return language;
};

export const LanguageSelector = () => {
    const { language, setLanguage } = useLanguage();
    return (
        <Box
            sx={{
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 0,
            }}
        >
            <Select
                size="small"
                variant="standard"
                value={language}
                onChange={(e) => {
                    setLanguage(e.target.value);
                }}
            >
                {Object.keys(languageMap).map((language) => (
                    <MenuItem key={language} value={language}>
                        {language.toUpperCase()}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};
