import { Box, Card, Chip, Typography } from '@mui/material';
import useLanguagepack, { useLanguage } from '../../languagepack/Languagepack';

const NotificationDisplay = ({ notifications }) => {
    const { language } = useLanguage();
    const languagepack = useLanguagepack();

    const getChipColor = (status) => {
        switch (status) {
            case 1: {
                return 'errorlight';
            }
            case 2: {
                return 'errorlight';
            }
            case 3: {
                return 'warning';
            }
            case 4: {
                return 'success';
            }
            case 5: {
                return 'info';
            }
            default: {
                return 'info';
            }
        }
    };

    return (
        <Box
            sx={{
                flex: 1,
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'flex-start',
            }}
        >
            {notifications.length === 0 && (
                <Typography textAlign="center" color="gray">
                    {languagepack.no_notifications}
                </Typography>
            )}
            {notifications.map((notification) => (
                <Card
                    key={notification.uuid}
                    sx={{
                        padding: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h6">
                            {
                                notification.notification_message?.[
                                    `title_${language}`
                                ]
                            }
                        </Typography>

                        <Chip
                            color={getChipColor(notification.status)}
                            size="small"
                            label={`${languagepack.status}: ${
                                languagepack[`status_${notification.status}`]
                            }`}
                        />
                    </Box>

                    <Typography>
                        {notification.notification_message?.[language]}
                    </Typography>
                    <Typography variant="caption" color="gray">
                        {languagepack.added_at}{' '}
                        {new Date(notification.created_at).toLocaleString(
                            navigator.language || 'pl-PL'
                        )}
                    </Typography>
                </Card>
            ))}
        </Box>
    );
};

export default NotificationDisplay;
