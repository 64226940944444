const pl = {
    home: 'Home',
    contact: 'Kontakt',
    no_notifications: 'Brak powiadomień systemowych',
    added_at: 'Dodano',
    contact_info: 'Dane kontaktowe',
    email: 'Email',
    phone: 'Telefon',
    status: 'Status',
    status_1: 'Jesteśmy świadomi powstania błędu',
    status_2: 'Badamy przyczynę błędu',
    status_3: 'Jesteśmy w trakcie naprawy',
    status_4: 'Błąd naprawiony',
    status_5: 'Informacja',
    global_status: 'Status wszystkich systemów',
    global_status_1: 'Wszystkie systemy działają poprawnie',
    global_status_2: 'Niektóre systemy nie działają poprawnie',
    global_status_3: 'Wszystkie systemy nie działają poprawnie',
};
export default pl;
