import { Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarProvider = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success');
    const [snackbarContent, setSnackbarContent] = useState('');

    useEffect(() => {
        snackbarService._showSnackbar = (type, content) => {
            setSnackbarOpen(true);
            setSnackbarType(type);
            setSnackbarContent(content);
        };
    }, []);

    return (
        <Snackbar
            open={snackbarOpen}
            onClose={() => {
                setSnackbarOpen(false);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={10 * 1000}
        >
            <Alert
                onClose={() => {
                    setSnackbarOpen(false);
                }}
                severity={snackbarType}
                sx={{ width: '100%' }}
            >
                {snackbarContent}
            </Alert>
        </Snackbar>
    );
};

class SnackbarService {
    _showSnackbar = null;

    showSnackbar(type, content) {
        this._showSnackbar(type, content);
    }
}
const snackbarService = new SnackbarService();
export default snackbarService;
