import dialogService from '../DialogService/DialogService';
import navigateService from '../NavigateService/NavigateService';
import { CODE_NOT_LOGGED_IN } from './apiCodes';

class ApiClient {
    baseUrlProd = 'https://igabinet.info/api/v1/';
    baseUrlDev = 'https://localhost:8095/api/v1/';
    _makeHeaders() {
        const headers = {
            'Content-Type': 'application/json',
        };

        const apiKey = localStorage.getItem('apiKey');
        if (apiKey) {
            headers['api-key'] = apiKey;
        }

        return headers;
    }

    _makeUrl(path, params = null) {
        const base =
            process.env.NODE_ENV === 'production'
                ? this.baseUrlProd
                : this.baseUrlDev;

        const url = new URL(path, base);
        if (params && typeof params === 'object') {
            Object.entries(params).forEach(([key, value]) => {
                url.searchParams.append(key, value);
            });
        }

        return url.href;
    }

    isLoggedIn() {
        const apiKey = localStorage.getItem('apiKey');

        return !!apiKey;
    }

    async getNotifications(page) {
        const response = await fetch(
            this._makeUrl('notifications', { page: page }),
            {
                headers: this._makeHeaders(),
                method: 'GET',
            }
        );

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async login(data) {
        const response = await fetch(this._makeUrl('login'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async logout() {
        const response = await fetch(this._makeUrl('admin/logout'), {
            headers: this._makeHeaders(),
            method: 'POST',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async changePassword(data) {
        const response = await fetch(this._makeUrl('admin/password'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getUsers() {
        const response = await fetch(this._makeUrl('admin/users'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getUser(uuid) {
        const response = await fetch(this._makeUrl(`admin/user/${uuid}`), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async addUser(data) {
        const response = await fetch(this._makeUrl('admin/user'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async deleteUser(data) {
        const response = await fetch(this._makeUrl('admin/user'), {
            headers: this._makeHeaders(),
            method: 'DELETE',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getAllPermissions() {
        const response = await fetch(this._makeUrl('admin/permissions'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async grantPermission(data) {
        const response = await fetch(this._makeUrl('admin/permissions'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async revokePermission(data) {
        const response = await fetch(this._makeUrl('admin/permissions'), {
            headers: this._makeHeaders(),
            method: 'DELETE',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getEmails(page) {
        const response = await fetch(
            this._makeUrl('admin/emails', { page: page }),
            {
                headers: this._makeHeaders(),
                method: 'GET',
            }
        );

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async sendEmail(data) {
        const response = await fetch(this._makeUrl('admin/email'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getCustomers() {
        const response = await fetch(this._makeUrl('admin/customers'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getAdminNotifications(page) {
        const response = await fetch(
            this._makeUrl('admin/notification', { page: page }),
            {
                headers: this._makeHeaders(),
                method: 'GET',
            }
        );

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getNotificationByUuid(uuid) {
        const response = await fetch(
            this._makeUrl(`admin/notification/${uuid}`),
            {
                headers: this._makeHeaders(),
                method: 'GET',
            }
        );

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async addNotification(data) {
        const response = await fetch(this._makeUrl('admin/notification'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async addNotificationMessage(data) {
        const response = await fetch(
            this._makeUrl('admin/notification-message'),
            {
                headers: this._makeHeaders(),
                method: 'POST',
                body: JSON.stringify(data),
            }
        );

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async editNotification(data) {
        const response = await fetch(this._makeUrl('admin/notification'), {
            headers: this._makeHeaders(),
            method: 'PATCH',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async editNotificationMessage(data) {
        const response = await fetch(
            this._makeUrl('admin/notification-message'),
            {
                headers: this._makeHeaders(),
                method: 'PATCH',
                body: JSON.stringify(data),
            }
        );

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async deleteNotification(data) {
        const response = await fetch(this._makeUrl('admin/notification'), {
            headers: this._makeHeaders(),
            method: 'DELETE',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getGlobalStatus() {
        const response = await fetch(this._makeUrl('global-status'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async setGlobalStatus(data) {
        const response = await fetch(this._makeUrl('admin/global-status'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getGlobalStatusHistory() {
        const response = await fetch(this._makeUrl('admin/global-status'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async requestPasswordReset(data) {
        const response = await fetch(this._makeUrl('reset-password-request'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async resetPassword(data) {
        const response = await fetch(this._makeUrl('reset-password'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getUrlsToCheck() {
        const response = await fetch(this._makeUrl('admin/urls-to-check'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async addUrlToCheck(data) {
        const response = await fetch(this._makeUrl('admin/urls-to-check'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async deleteUrlToCheck(data) {
        const response = await fetch(this._makeUrl('admin/urls-to-check'), {
            headers: this._makeHeaders(),
            method: 'DELETE',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getStatusChecksSummary() {
        const response = await fetch(this._makeUrl('admin/status-checks'), {
            headers: this._makeHeaders(),
            method: 'GET',
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getStatusChecksForUrl(url) {
        const response = await fetch(this._makeUrl('admin/status-checks'), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify({ url: url }),
        });

        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async truncateStatusChecks() {
        const response = await fetch(this._makeUrl('admin/status-checks'), {
            headers: this._makeHeaders(),
            method: 'DELETE',
        });
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        if (parsedResponse.code !== 0) {
            handleErrors(parsedResponse);
            return null;
        }

        return parsedResponse;
    }

    async getEventLogsGroupedByName(data) {
        const response = await fetch(
            this._makeUrl('admin/event-logs/grouped/by-name'),
            {
                headers: this._makeHeaders(),
                method: 'POST',
                body: JSON.stringify(data),
            }
        );
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        return parsedResponse;
    }

    async getEventLogsGroupedBySystem(data) {
        const response = await fetch(
            this._makeUrl('admin/event-logs/grouped/by-system'),
            {
                headers: this._makeHeaders(),
                method: 'POST',
                body: JSON.stringify(data),
            }
        );
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        return parsedResponse;
    }

    async getEventLogsByName(data) {
        const response = await fetch(
            this._makeUrl('admin/event-logs/by-name'),
            {
                headers: this._makeHeaders(),
                method: 'POST',
                body: JSON.stringify(data),
            }
        );
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        return parsedResponse;
    }

    async getEventLogsBySystem(data) {
        const response = await fetch(
            this._makeUrl('admin/event-logs/by-system'),
            {
                headers: this._makeHeaders(),
                method: 'POST',
                body: JSON.stringify(data),
            }
        );
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        return parsedResponse;
    }

    async getEventLogByUuid(uuid) {
        const response = await fetch(this._makeUrl(`admin/event-log/${uuid}`), {
            headers: this._makeHeaders(),
            method: 'POST',
        });
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        return parsedResponse;
    }

    async getEventLogStatistics(data) {
        const response = await fetch(this._makeUrl(`admin/event-logs/stats`), {
            headers: this._makeHeaders(),
            method: 'POST',
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            handleErrors();
            return null;
        }

        const parsedResponse = await response.json();
        return parsedResponse;
    }
}

const apiClient = new ApiClient();
export default apiClient;

export const handleErrors = (data = null) => {
    const title = 'Error';
    const content = data?.message || 'Error';
    if (data?.code === CODE_NOT_LOGGED_IN) {
        localStorage.removeItem('apiKey');
        localStorage.removeItem('permissions');
        localStorage.removeItem('eVisitsApiKey');
        navigateService.navigate('/admin/login');
    }
    dialogService.showDialog({ title, content });
};
