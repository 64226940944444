import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConfirmationProvider } from './components/confirmation/Confirmation';
import { DialogProvider } from './DialogService/DialogService';
import { LanguagepackProvider } from './languagepack/Languagepack';
import { NavigateProvider } from './NavigateService/NavigateService';
import { SnackbarProvider } from './SnackbarService/SnackbarService';
import { Suspense, lazy } from 'react';

import IndexPage from './pages';
import Loading from './components/loading/Loading';

const AccountPage = lazy(() => import('./pages/admin/account'));
const EmailsPage = lazy(() => import('./pages/admin/emails'));
const SendEmailPage = lazy(() => import('./pages/admin/emails/send'));
const LoginPage = lazy(() => import('./pages/admin/login'));
const LogoutPage = lazy(() => import('./pages/admin/logout'));
const NotificationsPage = lazy(() => import('./pages/admin/notifications'));
const AddNotificationPage = lazy(() =>
    import('./pages/admin/notifications/add')
);
const AddUserPage = lazy(() => import('./pages/admin/user/add'));
const UserEditPage = lazy(() => import('./pages/admin/user/[:uuid]'));
const UsersPage = lazy(() => import('./pages/admin/users'));
const EditNotificationPage = lazy(() =>
    import('./pages/admin/notifications/[:uuid]')
);
const ContactPage = lazy(() => import('./pages/contact'));
const MuiTheme = lazy(() => import('./theme/MuiTheme'));
const StatusPage = lazy(() => import('./pages/admin/status'));
const EvisitEventLogsPage = lazy(() =>
    import('./pages/admin/evisit/event-logs')
);
const EVisitsCallRoomsPage = lazy(() =>
    import('./pages/admin/evisit/call-rooms')
);
const ResetPasswordRequestPage = lazy(() =>
    import('./pages/admin/reset-password')
);
const ResetPasswordPage = lazy(() =>
    import('./pages/admin/reset-password/[:uuid]')
);
const UrlsToCheckPage = lazy(() => import('./pages/admin/urls-to-check'));
const CheckStatusPage = lazy(() => import('./pages/admin/check-status'));
const UrlStatusHistoryPage = lazy(() =>
    import('./pages/admin/urls-to-check/[:url]')
);
const EventLogPage = lazy(() => import('./pages/admin/event-log/EventLogPage'));
const EventLogByNamePage = lazy(() =>
    import('./pages/admin/event-log/by-name/EventLogByNamePage')
);
const EventLogBySystemPage = lazy(() =>
    import('./pages/admin/event-log/by-system/EventLogBySystemPage')
);
const EventLogDetailsPage = lazy(() =>
    import('./pages/admin/event-log/[:uuid]/EventLogDetailsPage')
);

function Router() {
    return (
        <MuiTheme>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <LanguagepackProvider>
                    <ConfirmationProvider>
                        <DialogProvider />
                        <SnackbarProvider />
                        <Suspense fallback={<Loading />}>
                            <BrowserRouter>
                                <NavigateProvider />
                                <Routes>
                                    <Route path="" element={<IndexPage />} />
                                    <Route
                                        path="contact"
                                        element={<ContactPage />}
                                    />
                                    <Route path="/admin">
                                        <Route
                                            path=""
                                            element={
                                                <Navigate
                                                    replace
                                                    to="/admin/login"
                                                />
                                            }
                                        />
                                        <Route
                                            path="login"
                                            element={<LoginPage />}
                                        />
                                        <Route
                                            path="logout"
                                            element={<LogoutPage />}
                                        />
                                        <Route
                                            path="account"
                                            element={<AccountPage />}
                                        />
                                        <Route
                                            path="users"
                                            element={<UsersPage />}
                                        />
                                        <Route
                                            path="user/add"
                                            element={<AddUserPage />}
                                        />
                                        <Route
                                            path="user/:uuid"
                                            element={<UserEditPage />}
                                        />
                                        <Route path="emails">
                                            <Route
                                                path=""
                                                element={<EmailsPage />}
                                            />
                                            <Route
                                                path="send"
                                                element={<SendEmailPage />}
                                            />
                                        </Route>
                                        <Route path="notifications">
                                            <Route
                                                path=""
                                                element={<NotificationsPage />}
                                            />
                                            <Route
                                                path=":uuid"
                                                element={
                                                    <EditNotificationPage />
                                                }
                                            />
                                            <Route
                                                path="add"
                                                element={
                                                    <AddNotificationPage />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="status"
                                            element={<StatusPage />}
                                        />
                                        <Route path="evisit">
                                            <Route
                                                path="event-logs"
                                                element={
                                                    <EvisitEventLogsPage />
                                                }
                                            />
                                            <Route
                                                path="call-rooms"
                                                element={
                                                    <EVisitsCallRoomsPage />
                                                }
                                            />
                                        </Route>
                                        <Route path="reset-password">
                                            <Route
                                                path=""
                                                element={
                                                    <ResetPasswordRequestPage />
                                                }
                                            />
                                            <Route
                                                path=":uuid"
                                                element={<ResetPasswordPage />}
                                            />
                                        </Route>
                                        <Route
                                            path="urls-to-check"
                                            element={<UrlsToCheckPage />}
                                        />
                                        <Route path="check-status">
                                            <Route
                                                path=""
                                                element={<CheckStatusPage />}
                                            />
                                            <Route
                                                path=":url"
                                                element={
                                                    <UrlStatusHistoryPage />
                                                }
                                            />
                                        </Route>
                                        <Route path="event-log">
                                            <Route
                                                path=""
                                                element={<EventLogPage />}
                                            />
                                            <Route
                                                path="by-name/:eventName"
                                                element={<EventLogByNamePage />}
                                            />
                                            <Route
                                                path="by-system/:systemUrl"
                                                element={
                                                    <EventLogBySystemPage />
                                                }
                                            />
                                            <Route
                                                path=":uuid"
                                                element={
                                                    <EventLogDetailsPage />
                                                }
                                            />
                                        </Route>
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </Suspense>
                    </ConfirmationProvider>
                </LanguagepackProvider>
            </LocalizationProvider>
        </MuiTheme>
    );
}

export default Router;
