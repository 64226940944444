import { Button, Dialog, Portal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const DialogProvider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        dialogService._showDialog = ({ title, content }) => {
            setIsOpen(true);
            setTitle(title || '');
            setContent(content || '');
        };
    }, []);

    const handleCloseDialog = () => {
        setIsOpen(false);
    };

    return (
        <Portal>
            <Dialog
                open={isOpen}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: {
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        minWidth: '50vw',
                    },
                }}
            >
                <Typography variant="h4">{title}</Typography>
                <Typography>{content}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseDialog}
                >
                    Close
                </Button>
            </Dialog>
        </Portal>
    );
};

class DialogService {
    _showDialog = null;

    showDialog({ title, content }) {
        this._showDialog({ title, content });
    }
}
const dialogService = new DialogService();
export default dialogService;
